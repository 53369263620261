import React from "react";
import {
    Box,
    Heading,
    Text,
    VStack,
    ListItem,
    UnorderedList,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button
} from "@chakra-ui/react";

export default function TermsAndConditions(props) {
    const _TNC_MODAL_TYPE = 'TNC';

    return (
        <Modal size={'4xl'} scrollBehavior="inside" isOpen={props?.modalType == _TNC_MODAL_TYPE} onClose={() => props?.closeModal()}>
            <ModalOverlay />
            <ModalContent>
                <ModalCloseButton />
                <ModalBody>
                    <Box padding={8} maxWidth="800px" mx="auto">
                        <Heading as="h1" size="xl" mb={6}>Terms and Conditions</Heading>
                        <VStack spacing={5} align="left">
                            <Heading as="h2" size="lg">1. Our Service</Heading>
                            <Text>
                                The Service includes all of the LogoHouse features, applications, services, technologies, and software that we provide. LogoHouse is an online platform that allows the community to share and upload user-generated entertainment content.
                            </Text>

                            <Heading as="h2" size="lg">2. The Data Policy</Heading>
                            <Text>
                                Providing our Service requires collecting and using your information. The Data Policy explains how we collect, use, and share information in LogoHouse. We collect several personal information in order to provide the Service to the community, including (but not limited to): email, name, and phone number. You must agree to the Data Policy to use LogoHouse.
                            </Text>

                            <Heading as="h2" size="lg">3. Your Commitments</Heading>
                            <Text>
                                In return for our commitment to provide the Service, we require you to make the below commitments to us.
                            </Text>

                            <Heading as="h3" size="md" mt={4}>3.1 Who can use LogoHouse</Heading>
                            <Text>
                                We want our Service to be as open and inclusive as possible, but we also want it to be safe, secure, and in accordance with the law. So, we need you to commit to a few restrictions in order to be part of the LogoHouse community.
                            </Text>
                            <UnorderedList pl={5}>
                                <ListItem>You must be at least 13 years old or the minimum legal age in your country to use LogoHouse.</ListItem>
                                <ListItem>We must not have previously disabled your account for violation of law or any of our policies.</ListItem>
                            </UnorderedList>

                            <Heading as="h3" size="md" mt={4}>3.2 How You Can't Use LogoHouse</Heading>
                            <Text>
                                Providing a safe and open Service for a broad community requires that we all do our part.
                            </Text>
                            <UnorderedList pl={5}>
                                <ListItem>You can't impersonate others or provide inaccurate information.</ListItem>
                                <ListItem>You can't do anything unlawful, misleading, or fraudulent or for an illegal or unauthorized purpose.</ListItem>
                                <ListItem>You can't do anything to interfere with or impair the intended operation of the Service.</ListItem>
                                <ListItem>You can't sell, license, or purchase any account or data obtained from us or our Service.</ListItem>
                                <ListItem>You can't post someone else's private or confidential information without permission or do anything that violates someone else's rights.</ListItem>
                                <ListItem>You can't use a domain name or URL in your username without our prior written consent.</ListItem>
                            </UnorderedList>

                            <Heading as="h3" size="md" mt={4}>3.3 Permissions you give to us</Heading>
                            <Text>
                                As part of our agreement, you also give us the permissions that we need to provide the Service. When you share, post, or upload content that is covered by intellectual property rights (such as photos or videos) on or in connection with our Service, you grant us a non-exclusive, royalty-free, transferable, sub-licensable, worldwide licence to host, use, distribute, modify, run, copy, publicly perform or display, translate, and create derivative works of your content (consistent with your privacy and application settings). This licence will end when your content is deleted from our systems.
                            </Text>

                            <Heading as="h3" size="md" mt={4}>3.4 What posts are not allowed on LogoHouse</Heading>
                            <UnorderedList pl={5}>
                                <ListItem>Intellectual Property - Ensure you have the right to post the content you share.</ListItem>
                                <ListItem>Appropriate Imagery - We don't allow nudity on LogoHouse.</ListItem>
                                <ListItem>Illegal Content - We prohibit support or praise of terrorism, organized crime, or hate groups. Offering sexual services, and selling firearms and drugs are also prohibited.</ListItem>
                                <ListItem>Hate Speech, Bullying, and Abuse - We remove credible threats of violence, hate speech, and abuse based on personal characteristics.</ListItem>
                                <ListItem>Self Injury - We do not allow content that glorifies or encourages self-injury, including eating disorders, unless for awareness purposes.</ListItem>
                                <ListItem>Graphic Violence - Graphic violence is not allowed, and we may remove such content to maintain a safe environment.</ListItem>
                            </UnorderedList>

                            <Heading as="h2" size="lg">4. Payment Services</Heading>
                            <Text>
                                LogoHouse allows a buyer to make payment for the purchase of credits using the payment methods made available on the Platform. All payment processing services for payment methods made available on the Platform will be undertaken and facilitated by the Payment Service Providers, and are subject to their respective Additional Terms.
                            </Text>

                            <Heading as="h3" size="md" mt={4}>4.1 Payment Services Provider</Heading>
                            <Text>
                                In order to use the Payment Services, you must provide LogoHouse and/or its related corporations with accurate and complete information required for the Payment Services, including account information of the preferred payment method. You agree to authorize LogoHouse to provide instructions to relevant Payment Service Providers for processing of the Payment Services.
                            </Text>

                            <Heading as="h2" size="lg">5. Additional Rights We Retain</Heading>
                            <Text>
                                If you select a username or similar identifier for your account, we may change it if we believe it is appropriate or necessary.
                            </Text>

                            <Heading as="h2" size="lg">6. Content Removal and Disabling or Terminating Your Account</Heading>
                            <Text>
                                We can remove any content or information that you share on the Service if we believe that it violates these Terms of Use or our policies. We can also terminate or change the Service if we determine it is necessary to avoid or mitigate legal risks.
                            </Text>

                            <Heading as="h2" size="lg">7. Purchasement of logo</Heading>
                            <Text>
                                7.1 Purchasement - All purchases are final, and no refunds will be made.
                            </Text>

                            <Heading as="h2" size="lg">8. Updating These Terms</Heading>
                            <Text>
                                We may change our Service and Policies, and we may need to update these Terms. You will have the opportunity to review them before they go into effect.
                            </Text>

                            <Heading as="h2" size="lg">9. Contact Information</Heading>
                            <Text>
                                If you wish to delete your content or account, or have any inquiries regarding these terms, please contact us by email at support@logohouse.com.my.
                            </Text>
                        </VStack>
                    </Box>
                </ModalBody>

                <ModalFooter>
                    <Button colorScheme='red' mr={3} onClick={() => props?.closeModal()}>
                        Close
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}