import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { Language } from '../common/constant';
import { ConfigEnum } from '../common/config';

import englishCommon from "./en/common.json";
import chineseCommon from "./zh/common.json";
import malayCommon from "./ms/common.json";

/// <summary>
/// Author: -
/// </summary>
const _resources = {};
_resources[Language._ENGLISH] = { common: englishCommon };
_resources[Language._CHINESE] = { common: chineseCommon };
_resources[Language._BAHASA_MELAYU] = { common: malayCommon };

i18n
    .use(initReactI18next)
    .init({
        defaultNS: "common",
        debug: false,
        resources: _resources,
        lng: localStorage.getItem(ConfigEnum._LANGUAGE) || Language._ENGLISH,
        fallbackLng: [Language._ENGLISH],
        keySeparator: false,
        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });


export default i18n;