import { mode } from "@chakra-ui/theme-tools";
export const textStyles = {
    components: {
        Text: {
            baseStyle: (props) => ({
                color: mode("black", "white")(props),
            })
        },
    },
};
