import React from 'react';
import LoadingOverlay from 'react-loading-overlay';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Spinner } from "@chakra-ui/react";

/// <summary>
/// Author: -
/// </summary>
const LoadingScreen = () => {
    var _isLoading = useSelector(state => state.appState.appIsBusy);
    const { t } = useTranslation();

    return (
        <LoadingOverlay
            active={_isLoading}
            spinner={<Spinner />}
            text={`${t('LOADING')} ...`}
            styles={{
                overlay: (base) => ({
                    ...base,
                    zIndex: '1000001',
                    position: 'fixed',
                })
            }} />
    )
}

export default LoadingScreen;