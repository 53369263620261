import { CloseButton, Flex, Link, Select, Text, useColorModeValue, HStack, Stack, Image, Box } from '@chakra-ui/react';
import { mode } from "@chakra-ui/theme-tools";
import { stringIsNullOrEmpty, numberWithCurrencyFormat } from "common/util";

const QuantitySelect = ({ value }) => {
    return (
        <Select
            maxW="64px"
            aria-label="Select quantity"
            focusBorderColor={useColorModeValue('blue.500', 'blue.200')}
            value={value}
        >
            <option value="1">1</option>
        </Select>
    )
}

const PriceTag = ({
    price, currency = "RM", salePrice
}) => {
    const defaultColor = mode('gray.700', 'gray.400')
    const onSaleColor = mode('gray.400', 'gray.700')
    const color = salePrice ? onSaleColor : defaultColor

    return (
        <HStack spacing="1">
            <Text
                as="span"
                fontWeight="medium"
                color={color}
                textDecoration={salePrice ? 'line-through' : 'none'}
            >
                {currency} {numberWithCurrencyFormat(price)}
            </Text>
            {salePrice && (
                <Text as="span" fontWeight="semibold" color={mode('gray.800', 'gray.100')}>
                    {currency} {numberWithCurrencyFormat(salePrice)}
                </Text>
            )}
        </HStack>
    );
}

const CartItem = ({
    name,
    description,
    quantity,
    data,
    currency,
    price,
    onChangeQuantity,
    onClickDelete
}) => {
    return (
        <Flex direction={{ base: 'column', md: 'row' }} justify="space-between" align="center">
            <Stack direction="row" spacing="5" width="full">
                <Image
                    rounded="lg"
                    width="120px"
                    height="120px"
                    fit="cover"
                    src={data}
                    alt={name}
                    draggable="false"
                    loading="lazy"
                />
                <Box pt="4">
                    <Stack spacing="0.5">
                        <Text fontWeight="medium">{name}</Text>
                        <Text color={mode('gray.600', 'gray.400')} fontSize="sm">
                            {description}
                        </Text>
                    </Stack>
                </Box>
            </Stack>

            {/* Desktop */}
            <Flex width="full" justify="space-between" display={{ base: 'none', md: 'flex' }}>
                <QuantitySelect
                    value={quantity}
                    onChange={(e) => {
                        onChangeQuantity?.(+e.currentTarget.value)
                    }}
                />
                <PriceTag price={price} currency={currency} />
                <CloseButton aria-label={`Delete ${name} from cart`} onClick={onClickDelete} />
            </Flex>

            {/* Mobile */}
            <Flex
                mt="4"
                align="center"
                width="full"
                justify="space-between"
                display={{ base: 'flex', md: 'none' }}
            >
                <Link fontSize="sm" textDecor="underline" onClick={onClickDelete}>
                    Delete
                </Link>
                <QuantitySelect
                    value={quantity}
                    onChange={(e) => {
                        onChangeQuantity?.(+e.currentTarget.value)
                    }}
                />
                <PriceTag price={price} currency={currency} />
            </Flex>
        </Flex>
    )
}

export default CartItem;