import React, { useEffect, useState, useContext } from 'react';
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import { stringIsNullOrEmpty, createMultiPartFormBody, isObjectEmpty } from '../../../common/util';
import { ApiKey, OrderStatus } from '../../../common/constant';
import { WebUrl } from '../../../routes';
import { useRouter } from '../../../common/useRouter';
import ApiEngine from 'common/api-engine';
import { useDispatch } from "react-redux";
import { showCustomDialog, setBusy, setIdle, } from "../../../application/action/app_action";
import { LoadingOutlined } from '@ant-design/icons';
import { Flex, Spin, Space } from 'antd';
import { ToggleContent } from '../../../app.js';

/// <summary>
/// Author: YJ
/// </summary>
const TransactionInit = () => {
    var _location = useLocation();
    var _router = useRouter();
    const _dispatch = useDispatch();
	const { toggleTopBar } = useContext(ToggleContent);

    useEffect(() => {
        if (toggleTopBar != null) {
            toggleTopBar(false);
        }

        return() => {
            toggleTopBar(true);
        }
    }, [toggleTopBar])

    useEffect(() => {
        (async() => {
            try {
                const params = new URLSearchParams(window.location.search);

                const data = {
                    code: params.get('code'),
                    secret: params.get('secret'),
                    signature: params.get('signature'),
                    email: params.get('email'),
                    amount: params.get('amount'),
                    refNo: params.get('refNo'),
                    backendUrl: params.get('backendUrl'),
                    callbackUrl: params.get('callbackUrl'),
                    billName: params.get('billName'),
                    billEmail: params.get('billEmail'),
                    billMobile: params.get('billMobile'),
                    billAddress1: params.get('billAddress1'),
                    billAddress2: params.get('billAddress2'),
                    billZipCode: params.get('billZipCode'),
                    billCity: params.get('billCity'),
                    billState: params.get('billState'),
                    deliveryName: params.get('deliveryName'),
                    deliveryEmail: params.get('deliveryEmail'),
                    deliveryMobile: params.get('deliveryMobile'),
                    deliveryAddress1: params.get('deliveryAddress1'),
                    deliveryAddress2: params.get('deliveryAddress2'),
                    deliveryZipCode: params.get('deliveryZipCode'),
                    deliveryCity: params.get('deliveryCity'),
                    deliveryState: params.get('deliveryState'),
                    cardNumber: params.get('cardNumber'),
                    cvc: params.get('cvc'),
                    expMonth: params.get('expMonth'),
                    expYear: params.get('expYear'),
                    cardHolderName: params.get('cardHolderName')
                  };

                if (
                    stringIsNullOrEmpty(data.code)
                    ||
                    stringIsNullOrEmpty(data.signature)
                    ||
                    stringIsNullOrEmpty(data.email)
                    ||
                    stringIsNullOrEmpty(data.amount)
                    ||
                    stringIsNullOrEmpty(data.refNo)
                ) {
                    throw 'Invalid session';
                }

                const response = await ApiEngine.post('/transaction/init', data);

                if (!response[ApiKey._API_SUCCESS_KEY]) {
                    throw response[ApiKey._API_MESSAGE_KEY];
                }
                
                if (response[ApiKey._API_DATA_KEY].includes("http")) {
                    window.location.href = response[ApiKey._API_DATA_KEY];
                }
                else {
                    _router.navigate(response[ApiKey._API_DATA_KEY]);
                }
            }
            catch(err) {
                console.error(err);
                _dispatch(showCustomDialog({
                    content: err
                }));
            }
        })();
    }, []);

    return (
        <Space direction="horizontal" style={{width: '100%', height: '100vh', justifyContent: 'center', alignContent: 'center'}}>
            <Flex align="center">
                <Spin indicator={<LoadingOutlined style={{ fontSize: 96 }} spin />} />
            </Flex>
        </Space>
    );
};

export default TransactionInit;
