import { _WEB_VERSION } from './constant';
// export const _BASE_URL = 'https://localhost:5001'; // Stage
export const _BASE_URL = 'https://api.logohouse.art'; // Production

const Routes = {
    _ACCOUNT_LOGIN: '/account/login',
    _USER_REGISTER: '/account/register',
    _FORGOT_PASSWORD: '/account/forgot-password',
    _CHANGE_PASSWORD: '/account/change-password',
    _ACCOUNT_VERIFY: 'account/verify',
    _ACCOUNT_LOGOUT: '/account/logout',
    _ACCOUNT_IS_LOGGED_IN: '/account/is-logged-in',
};

export default Routes;
