import axios from "axios";
import { stringIsNullOrEmpty } from "../../common/util";
import { ImageContentType } from "../../constants/file";

/**
 * @author Nelson
 */
export async function convertBase64ToFile(data = { fileName: "", contentType: "", content: "" }) {
    var file = null;

    if (!stringIsNullOrEmpty(data.content)) {
        if (stringIsNullOrEmpty(data.contentType)) {
            data.contentType = ImageContentType.Png;
        }

        if (data.content.startsWith('data')) {
            data.content = data.content.replace(`data:${data.contentType};base64,`, '');
        }

        var byteCharacters = atob(data.content);

        var byteArray = new Uint8Array(byteCharacters.length);

        for (let i = 0; i < byteCharacters.length; i++) {
            byteArray[i] = byteCharacters.charCodeAt(i);
        }

        file = new File([new Blob([byteArray], { type: data.contentType })], data.fileName, { type: data.contentType });
    }

    return file;
}

/**
 * @author Nelson
 */
export async function convertImageUrlToBase64(url) {
    let response = await axios.get(`${url}&v=${Date.now().toString()}`, { responseType: 'blob' });

    return await convertBlobDataToBase64(response.data);
}

/**
 * @author Nelson
 */
export async function convertBlobDataToBase64(blob) {
    return new Promise((resolve, reject) => {
        if (blob === undefined || blob === null) {
            reject(new Error('Invalid file object'));
        }
        else {
            let reader = new FileReader();

            reader.onloadend = () => {
                resolve(reader.result);
            };

            reader.onerror = reject;

            reader.readAsDataURL(blob);
        }
    });
}

/**
 * @author Nelson
 */
export function convertFileToBlobArrayData(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onload = () => {
            const arrayBuffer = reader.result;

            resolve({
                buffer: arrayBuffer,
                type: file.type
            });
        };

        reader.onerror = reject;

        reader.readAsArrayBuffer(file);
    });
}

/**
 * @author Nelson
 */
export function convertBlobDataToFile(blob, fileName) {
    return new File([blob], fileName, { type: blob.type });
}

/**
 * @author Nelson
 */
export async function convertImageUrlToBlobData(url) {
    let response = await axios.get(`${url}&v=${Date.now().toString()}`, { responseType: 'blob' });

    return response.data;
}

/**
 * @author Nelson
 */
export async function convertImageUrlToBlobArrayData(url) {
    let response = await axios.get(`${url}&v=${Date.now().toString()}`, { responseType: 'blob' });

    return {
        buffer: await response.data.arrayBuffer(),
        type: response.data.type
    };
}

/**
 * @author Nelson
 */
export function convertBlobArrayDataToBlobData(content) {
    return new Blob([content.buffer], { type: content.type });
}

/**
 * @author Nelson
 */
export function convertBlobArrayDataToBlobUrl(content) {
    return URL.createObjectURL(convertBlobArrayDataToBlobData(content));
}

/**
 * @author Nelson
 */
export function convertBlobArrayDataToFile(content, name) {
    return convertBlobDataToFile(convertBlobArrayDataToBlobData(content), name);
}

/**
 * @author Nelson
 * @param {*} hexString 
 * @param {*} contentType 
 * @returns 
 */
export function convertHexStringToBlobData(hexString, contentType = ImageContentType.Jpeg) {
    const byteNumbers = new Uint8Array(hexString.length / 2);

    for (let i = 0; i < hexString.length; i += 2) {
        byteNumbers[i / 2] = parseInt(hexString.substr(i, 2), 16);
    }

    return new Blob([byteNumbers], { type: contentType });
}