import React from 'react';
import { useTranslation } from 'react-i18next';
import {
	Avatar,
	Flex,
	Icon,
	Menu,
	MenuButton,
	MenuItem,
	MenuList,
	MenuOptionGroup,
	MenuItemOption,
	Text,
	useColorModeValue,
	Spacer,
	useColorMode,
	Button
} from '@chakra-ui/react';
import { ItemContent } from 'components/menu/ItemContent';
import { SidebarResponsive } from 'components/sidebar/Sidebar';
import PropTypes from 'prop-types';
import { MdNotificationsNone } from 'react-icons/md';
import { LuLanguages } from "react-icons/lu";
import { _AUTH_ROUTES } from "../../routes";
import { useDispatch, useSelector } from 'react-redux';
import { performLogout } from '../../application/action/auth_action';
import { _LANGUAGE_OPTION, Role } from '../../common/constant';
import { ConfigEnum } from '../../common/config';
import { filterMenu } from '../../common/util';
import { useRouter } from '../../common/useRouter';
import { WebUrl } from '../../routes';
import { IoMdMoon, IoMdSunny } from "react-icons/io";


/// <summary>
/// Author: -
/// </summary>
export default function HeaderLinks(props) {
	var _dispatch = useDispatch();
	var _router = useRouter();
	const _USER_DATA = useSelector((state) => state.authState.userData);
	const { t, i18n } = useTranslation();
	const { secondary } = props;
	const navbarIcon = useColorModeValue('gray.400', 'white');
	let menuBg = useColorModeValue('white', 'navy.800');
	const textColor = useColorModeValue('secondaryGray.900', 'white');
	const textColorBrand = useColorModeValue('brand.700', 'brand.400');
	const borderColor = useColorModeValue('#E6ECFA', 'rgba(135, 140, 189, 0.3)');
	const shadow = useColorModeValue(
		'14px 17px 40px 4px rgba(112, 144, 176, 0.18)',
		'14px 17px 40px 4px rgba(112, 144, 176, 0.06)'
	);
	const { colorMode, toggleColorMode } = useColorMode();
	
	/// <summary>
	/// Author: -
	/// </summary>
	function onChangeLanguage(language) {
		i18n.changeLanguage(language);
		localStorage.setItem(ConfigEnum._LANGUAGE, language);
	}

	return (
		<Flex
			w={{ sm: '100%', md: 'auto' }}
			alignItems="center"
			flexDirection="row"
			justifyContent={'flex-end'}
			bg={menuBg}
			flexWrap={secondary ? { base: 'wrap', md: 'nowrap' } : 'unset'}
			p="3px"
			borderRadius="30px"
			boxShadow={shadow}>
			{/* <SidebarResponsive routes={filterMenu(_AUTH_ROUTES, _USER_DATA?.user?.roleId)} /> */}
			{/* <Menu>
				<MenuButton p="0px">
					<Icon mt="6px" as={MdNotificationsNone} color={navbarIcon} w="18px" h="18px" me="10px" />
				</MenuButton>
				<MenuList
					boxShadow={shadow}
					p="20px"
					borderRadius="20px"
					bg={menuBg}
					border="none"
					mt="22px"
					me={{ base: '30px', md: 'unset' }}
					minW={{ base: 'unset', md: '400px', xl: '450px' }}
					maxW={{ base: '360px', md: 'unset' }}>
					<Flex jusitfy="space-between" w="100%" mb="20px">
						<Text fontSize="md" fontWeight="600" color={textColor}>
							Notifications
						</Text>
						<Text fontSize="sm" fontWeight="500" color={textColorBrand} ms="auto" cursor="pointer">
							Mark all read
						</Text>
					</Flex>
					<Flex flexDirection="column">
						<MenuItem _hover={{ bg: 'none' }} _focus={{ bg: 'none' }} px="0" borderRadius="8px" mb="10px">
							<ItemContent info="Horizon UI Dashboard PRO" aName="Alicia" />
						</MenuItem>
						<MenuItem _hover={{ bg: 'none' }} _focus={{ bg: 'none' }} px="0" borderRadius="8px" mb="10px">
							<ItemContent info="Horizon Design System Free" aName="Josh Henry" />
						</MenuItem>
					</Flex>
				</MenuList>
			</Menu> */}

			<Menu>
				<MenuList>
					<MenuOptionGroup defaultValue={localStorage.getItem(ConfigEnum._LANGUAGE)}>
						{
							_LANGUAGE_OPTION.map((item, index) => {
								return (
									<MenuItemOption
										key={index}
										type='radio'
										value={item.value}
										onClick={() => onChangeLanguage(item.value)}>
										{item.label}
									</MenuItemOption>
								)
							})
						}
					</MenuOptionGroup>
				</MenuList>
			</Menu>
			{/* <Spacer /> */}
			<Menu>
				<MenuButton p="0px">
					<Avatar
						_hover={{ cursor: 'pointer' }}
						color="white"
						name={`${_USER_DATA?.username}`}
						bg="#11047A"
						size="sm"
						w="40px"
						h="40px"
					/>
				</MenuButton>
				<MenuList boxShadow={shadow} p="0px" mt="10px" borderRadius="20px" bg={menuBg} border="none">
					<Flex w="100%" mb="0px">
						<Text
							ps="20px"
							pt="16px"
							pb="10px"
							w="100%"
							borderBottom="1px solid"
							borderColor={borderColor}
							fontSize="sm"
							fontWeight="700"
							color={textColor}>
							👋&nbsp; {t('HI')}, {_USER_DATA?.username}
						</Text>
					</Flex>
					<Flex flexDirection="column" p="10px">
						<MenuItem _hover={{ bg: 'none' }} _focus={{ bg: 'none' }} borderRadius="8px" px="14px">
							<Text fontSize="sm">Profile Settings</Text>
						</MenuItem>
						<MenuItem
							_hover={{ bg: 'none' }}
							_focus={{ bg: 'none' }}
							color="red.400"
							borderRadius="8px"
							px="14px"
							onClick={() => _dispatch(performLogout())}>
							<Text fontSize="sm">{t('LOG_OUT')}</Text>
						</MenuItem>
					</Flex>
				</MenuList>
			</Menu>
		</Flex>
	);
}

HeaderLinks.propTypes = {
	variant: PropTypes.string,
	fixed: PropTypes.bool,
	secondary: PropTypes.bool,
	onOpen: PropTypes.func
};
