import React, { useState, useEffect, useContext } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import { Box, useColorModeValue, useDisclosure } from "@chakra-ui/react";
import { SidebarContext } from "contexts/SidebarContext";
import { WebUrl, _NON_AUTH_ROUTES } from "../../routes";
import { useRouter } from "../../common/useRouter";
import { isPathExist, isObjectEmpty } from "../../common/util";
import { useSelector } from 'react-redux';
import Footer from '../../components/footer/FooterAdmin.js';
import Navbar from '../../components/navbar/NavbarHeader.js';
import { ToggleContent } from '../../app.js';

/// <summary>
/// Author: -
/// </summary>
export default function NonAuth(props) {
  var _router = useRouter();
  const _USER_DATA = useSelector((state) => state.authState.userData);
  const authBg = useColorModeValue("white", "navy.900");
  const [toggleSidebar, setToggleSidebar] = useState(false);
	const { ...rest } = props;
	const [fixed] = useState(false);
  const _currentLocation = useLocation();
  const { onOpen } = useDisclosure();
	const { renderTopBar } = useContext(ToggleContent);

  /// <summary>
  /// Author: -
  /// </summary>
  useEffect(() => {
    if (_currentLocation) {
      if (isObjectEmpty(isPathExist(_NON_AUTH_ROUTES, _currentLocation.pathname))) {
        _router.navigate(WebUrl._DEFAULT, true);
      }
    }
  }, [_currentLocation]);

  /// <summary>
  /// Author: -
  /// </summary>
  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.component) {
        return (
          <Route
            path={prop.path}
            element={prop.component}
            key={key}
          />
        );
      }
      else if (prop.category) {
        return getRoutes(prop.items);
      }
      else {
        return null;
      }
    });
  };
  /// <summary>
	/// Author: -
	/// </summary>
	const getActiveRoute = (routes) => {
		return isPathExist(routes, _currentLocation.pathname)?.name ?? 'LOGOHOUSE';
	};

  return (
    <Box>
      <Box
        minHeight='100vh'
        height='100%'
        overflow='auto'
        position='relative'
        maxHeight='100%'
        // w={{ base: '100%', xl: 'calc( 100% - 70px )' }}
        // maxWidth={{ base: '100%', xl: 'calc( 100% - 70px )' }}
        transition='all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)'
        transitionDuration='.2s, .2s, .35s'
        transitionProperty='top, bottom, width'
        transitionTimingFunction='linear, linear, ease'>
        {renderTopBar && <Navbar
          onOpen={onOpen}
          logoText={'Horizon UI Dashboard PRO'}
          brandText={getActiveRoute(_NON_AUTH_ROUTES)}
          fixed={fixed}
          {...rest}
        />}
        <Box mx='auto' minH='100vh'>
          <Routes location={_currentLocation}>
            {getRoutes(_NON_AUTH_ROUTES)}
          </Routes>
        </Box>
        <Box>
          <Footer />
        </Box>
      </Box>
    </Box>
  );
}
