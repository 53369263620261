import React, { useState, useEffect } from "react";
import {
    Box,
    Flex,
    HStack,
    Heading,
    Link,
    Stack,
    useColorModeValue as mode,
    Text,
    Button,
    Container
} from '@chakra-ui/react'
import { useDispatch, useSelector } from "react-redux";
import { ApiKey } from "../../common/constant";
import { WebUrl } from "../../routes";
import { showCustomDialog, setBusy, setIdle, } from "../../application/action/app_action";
import ApiEngine from "../../common/api-engine";
import CartItem from "../../components/CartItem";
import { FaArrowRight } from 'react-icons/fa'
import { numberWithCurrencyFormat } from "common/util";
import { convertHexStringToBlobData } from "../../utils/file";
import { isObjectEmpty } from "common/util";
import _ from 'lodash';
import useDatabase, { Table } from "../../hooks/useDatabase";
import { _SEARCH_DESCRIPTION_CACHE, _SESSION_KEY } from "../../views/non-auth/home";
import { stringIsNullOrEmpty } from "common/util";
import { useNavigate } from "react-router-dom";

/// <summary>
/// Author: CK
/// </summary>
function Cart() {
    var _navigate = useNavigate();
    const _dispatch = useDispatch();
    const [cartData, setCartData] = useState([]);
    const sessionId = useSelector(state => state.authState.sessionId);

    const { truncate } = useDatabase();

    /**
     * @author CK
     */
    useEffect(() => {
        (
            async () => {
                _dispatch(setBusy());

                try {
                    let cartData = [];
                    const response = await ApiEngine.get('/order/cart');

                    if (!response[ApiKey._API_SUCCESS_KEY]) {
                        throw response[ApiKey._API_MESSAGE_KEY];
                    }

                    if (!isObjectEmpty(response[ApiKey._API_DATA_KEY]) && response[ApiKey._API_DATA_KEY].items.length > 0) {
                        const items = response[ApiKey._API_DATA_KEY].items;

                        for (let i = 0; i < items.length; i++) {
                            cartData.push({
                                data: URL.createObjectURL(convertHexStringToBlobData(items[i].data)),
                                price: items[i].price,
                                name: items[i].name,
                                id: items[i].id
                            });
                        }

                        setCartData(cartData);
                    }
                }
                catch (error) {
                    _dispatch(showCustomDialog({ success: false, content: error }));
                }
                finally {
                    _dispatch(setIdle());
                }
            }
        )();
    }, []);

    /**
     * @author Nelson
     */
    async function onSubmit() {
        if (cartData.length > 0) {
            _dispatch(showCustomDialog({
                content: 'Please carefully review your order items before placing the order.',
                cancelTxt: 'No',
                onCancel: () => { },
                confirmTxt: 'Yes',
                onConfirm: async () => {
                    const response = await ApiEngine.post('/order/place', JSON.stringify({
                        grandTotal: _.sum(cartData.map((data) => data.price)),
                        items: cartData.map((data) => data.name),
                        sessionId
                    }));

                    if (!response[ApiKey._API_SUCCESS_KEY]) {
                        _dispatch(showCustomDialog({
                            success: false,
                            content: response[ApiKey._API_MESSAGE_KEY]
                        }));
                    }
                    else {
                        if (!stringIsNullOrEmpty(response[ApiKey._API_DATA_KEY])) {
                            setCartData([]);

                            await truncate(Table.CartItemGeneration);

                            sessionStorage.removeItem(_SESSION_KEY);
                            sessionStorage.removeItem(_SEARCH_DESCRIPTION_CACHE);
                            window.location.href = response[ApiKey._API_DATA_KEY];
                            // _navigate(response[ApiKey._API_DATA_KEY]);
                        }
                        else {
                            setCartData([]);

                            await truncate(Table.CartItemGeneration);

                            sessionStorage.removeItem(_SESSION_KEY);
                            sessionStorage.removeItem(_SEARCH_DESCRIPTION_CACHE);

                            _dispatch(showCustomDialog({
                                success: true,
                                content: 'Order placed successfully'
                            }));
                        }
                    }
                }
            }));
        }
        else {
            _dispatch(showCustomDialog({
                content: 'Please generate and select at least 1 logo to proceed checkout.'
            }));
        }
    }

    /**
     * @author Nelson
     * @param {*} item 
     */
    async function onDelete(item) {
        _dispatch(showCustomDialog({
            content: 'Are you sure you want to remove this item ? Once removed, it cannot be retrieved.',
            confirmTxt: 'Yes',
            cancelTxt: 'No',
            onCancel: () => { },
            onConfirm: async () => {
                const response = await ApiEngine.delete(`/order/cart/${item.name}`);

                _dispatch(showCustomDialog({ success: response[ApiKey._API_SUCCESS_KEY], content: response[ApiKey._API_SUCCESS_KEY] === true ? 'Removed successfully' : response[ApiKey._API_MESSAGE_KEY] }));

                if (response[ApiKey._API_SUCCESS_KEY]) {
                    const newItems = cartData.filter(e => e.name !== item.name).slice(0);

                    setCartData([...newItems]);
                }
            }
        }));
    }

    return (
        <Box py={'50px'}>
            <Container>
                <Stack
                    direction={{ base: 'column', lg: 'row' }}
                    align={{ lg: 'flex-start' }}
                    spacing={{ base: '8', md: '16' }}
                >
                    <Stack spacing={{ base: '8', md: '10' }} flex="2">
                        <Heading fontSize="2xl" fontWeight="extrabold">
                            Shopping Cart ({cartData.length} items)
                        </Heading>

                        <Stack spacing="6">
                            {cartData.map((item) => (
                                <CartItem onClickDelete={async () => await onDelete(item)} key={item.id} {...item} />
                            ))}
                        </Stack>
                    </Stack>

                    <Flex direction="column" align="center" flex="1">
                        <Stack spacing="8" borderWidth="1px" rounded="lg" padding="8" width="full">
                            <Heading size="md">Order Summary</Heading>

                            <Stack spacing="6">
                                <Flex justify="space-between" fontSize="sm">
                                    <Text fontWeight="medium" color={mode('gray.600', 'gray.400')}>
                                        Subtotal
                                    </Text>
                                    <Text fontWeight="medium">RM {numberWithCurrencyFormat(cartData.reduce((sum, item) => sum + item.price, 0))}</Text>
                                </Flex>
                                <Flex justify="space-between" fontSize="sm">
                                    <Text fontWeight="medium" color={mode('gray.600', 'gray.400')}>
                                        Shipping + Tax
                                    </Text>
                                    <Text fontWeight="medium">
                                        <Link href="#" textDecor="underline">
                                            Calculate shipping
                                        </Link>
                                    </Text>
                                </Flex>
                                <Flex justify="space-between" fontSize="sm">
                                    <Text fontWeight="medium" color={mode('gray.600', 'gray.400')}>
                                        Coupon Code
                                    </Text>
                                    <Text fontWeight="medium">
                                        <Link href="#" textDecor="underline">
                                            Add coupon code
                                        </Link>
                                    </Text>
                                </Flex>
                                <Flex justify="space-between" fontSize="sm">
                                    <Text fontWeight="medium" color={mode('gray.600', 'gray.400')}>
                                        Total
                                    </Text>
                                    <Text fontWeight="medium">
                                        <Link href="#" textDecor="underline">
                                            RM {numberWithCurrencyFormat(cartData.reduce((sum, item) => sum + item.price, 0))}
                                        </Link>
                                    </Text>
                                </Flex>
                            </Stack>
                            <Button onClick={onSubmit} colorScheme="blue" size="lg" fontSize="md" rightIcon={<FaArrowRight />}>
                                Checkout
                            </Button>
                        </Stack>
                        <HStack mt="6" fontWeight="semibold">
                            <p>or</p>
                            <Link href={WebUrl._DEFAULT} color={mode('blue.500', 'blue.200')}>Continue shopping</Link>
                        </HStack>
                    </Flex>
                </Stack>
            </Container>
        </Box>
    );
}

export default Cart;